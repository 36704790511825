/** @jsx jsx */
import { graphql } from "gatsby"
import { jsx } from "theme-ui"
import Image from "gatsby-image"
import { Box, Flex, Heading, Text } from "@theme-ui/components"
import Layout from "../components/organisms/Layout"
import { StructuredText } from "react-datocms"
import BreadCrumbs from '../components/organisms/Breadcrumbs'
import SideNav from '../components/organisms/SideNav'
import Container from '../components/organisms/Container'
import Emoji from 'react-emoji-render';
import { MDXProvider } from "@mdx-js/react"
import { MDXRenderer } from 'gatsby-plugin-mdx'
import { Collapsible } from '@component-controls/components';
import { useState } from 'react'
import { faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { getTopicUrl } from '../utils'
import { Note, DocImage, ReadMore } from '../components/shortcodes';
import { SEO as Seo  } from "../components";
import styled from '@emotion/styled'

const StyledTable = styled.table`
  border-collapse: collapse;
  width: 100%;
  thead {
    background-color: ${props => props.theme.colors.headerBg};
  }
  th, td {
    padding: 12px 16px;
    border: 1px solid #e2e8f0;
    min-width: 153px;
  }
`;

const shortcodes = { Note, DocImage, ReadMore };

const MdxContent = ({ body }) => {
  return (
    <MDXProvider components={shortcodes}>
      <MDXRenderer sx={{ overflow: 'hidden' }}>{body}</MDXRenderer>
    </MDXProvider>
  )
}

const Table = ({record}) => {
  const recordObj = JSON.parse(record.data);
  const columns = recordObj?.columns;
  const rowData = recordObj?.data;

  return (
		<Box my={3} sx={{width: "100%", overflowX: "scroll"}}>
			<StyledTable>
				<thead>
					<tr>
						{columns?.map((name, i) => (
							<th key={`col-${i}`}>{name}</th>
						))}
					</tr>
				</thead>
				<tbody>
					{rowData?.map((row, i) => (
						<tr key={`row-${i}`}>
							{Object.values(row)?.map((value, i) => (
								<td key={`val-${i}`}>{value}</td>
							))}
						</tr>
					))}
				</tbody>
			</StyledTable>
		</Box>
	);
}

const Video = ({record}) => {
  return (
		<Box sx={{ maxWidth: "770px", mt: 4, mb: 3 }}>
			{record?.videoUrl ? (
				<iframe
					src={record?.videoUrl}
					frameborder="0"
					allowfullscreen
					height="400"
					width="100%"
					referrerpolicy="strict-origin-when-cross-origin"
				></iframe>
			) : record?.source && record?.source?.format === "gif" ? (
				<img src={record.source.url} style={{ width: "100%" }} />
			) : (
				<video width="100%" controls>
					<source src={record?.source?.url}></source>
				</video>
			)}
		</Box>
	);
}

const Screenshot = ({ record }) => {
  if(record.image.format === "mp4") {
    return null;
  }
  else {
    return <Image fluid={record.image.fluid} />;
  }
}

const Accordion = ({ record }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Box sx={{ mt: 2, mb: 3, p: 2, borderWidth: 1, borderStyle: 'solid', borderColor: 'gray.5'  }}>
      <Flex sx={{  justifyContent: 'start', alignItems: 'center', width: '100%', }} onClick={() => setIsOpen(!isOpen)}>
        <Heading sx={{ m: 0, mr: 3 }} variant="h2" as="h2">{record.title}</Heading>
      <FontAwesomeIcon icon={isOpen ? faChevronUp : faChevronDown } />
      </Flex>
      <Collapsible sx={{ width: '100%', overflow: 'hidden' }} isOpen={isOpen}>
        <MdxContent body={record.bodyNode.childMdx.body} />
      </Collapsible>
    </Box>
  )
}

const DatoCmsNote = ({ note }) => {
  const NOTE = "NOTE";
  const bgColors = { TIP: 'green.1', WARN: 'red.1', NOTE: '#FFF', INFO: 'blue.1' }
  const colors = { TIP: 'green.6', WARN: 'red.6', NOTE: 'text', INFO: 'blue.5' }
  const emojis = { TIP: '💡', WARN: '⚠️', NOTE: '📌', 'INFO': 'ℹ️' }
  const noteTypeRegex = /^(.+?)\:(.*)$/;
  const parts = note.body.match(noteTypeRegex);
  const body = (parts && parts[2]) || note.body;
  const type = (parts && parts[1]?.toUpperCase()) || NOTE;
  const color = colors[type] || colors[NOTE];
  const emoji = emojis[type] || emojis[NOTE];
  const bgColor = bgColors[type] || bgColors[NOTE];
  const boxStyle = {
    border: '1px solid gray',
    px: 1,
    py: 0,
    mx: [2, 3],
    mt: 4,
    mb: 5,
    borderRadius: 5,
    borderColor: color,
    backgroundColor: bgColor,
  }

  return (
    <Flex dir="row" sx={{ ...boxStyle, justifyContent: 'flex-start', alignContent: 'center', alignItems: 'center' }}>
      <Emoji sx={{ fontSize: 4, mr: 3, color }} text={emoji} />
      <Text>{body}</Text>
    </Flex>
  )
}

const Article = ({ app, article }) => {
  const t1 = article.topic;
  const t0 = article.topic?.parentTopic;

  const crumbs = [];
  crumbs.push({ title: app.title, url: `/${app.slug}` })
  if (t0 !== null) {
    crumbs.push({ title: t0.title, url: getTopicUrl(t0) })
  } else {
    crumbs.push({ title: t1.title, url: getTopicUrl(t1) })
  }
  // crumbs.push({ title: article.title })

  return (
    <Box sx={{ pt: 3, ml: 4, mr: 6 }} >
      <BreadCrumbs crumbs={crumbs} />
      <Heading variant="h1" as="h1">{article.title}</Heading>

      <StructuredText
        data={article.body}
        renderBlock={({ record }) => {
          if (record.__typename === "DatoCmsScreenshot" && record?.image) {
            return <Screenshot record={record} />;
          }
          if (record.__typename === "DatoCmsNote") {
            return <DatoCmsNote note={record} />;
          }
          if (record.__typename === "DatoCmsCollapsible") {
            return <Accordion record={record} />;
          }
          if (record.__typename === "DatoCmsVideo" && (record?.videoUrl || record?.source)) {
            return <Video record={record} />
          }
          if (record.__typename === "DatoCmsTable" && record?.data) {
            return <Table record={record} />
          }
        }}
      />
    </Box>
  )
}

const ArticleTemplate = ({
  data: { article, related_articles },
  location,
}) => {
  const {topic, seo} = article
  const app = topic.app;

  const search = {showSearch: true, filterApps: app.slug}

  return (
    <Layout sx={{ border: '1px solid blue' }} search={search}>
      <Seo datoSeo={seo} pathname={location.pathname} />      
      <Container>
        <Flex>
          <Box sx={{ minWidth: '300px', }}>
            <SideNav appId={app.id} topic={topic} location={location} articles={related_articles} />
          </Box>
          <Article app={app} article={article} />
          <Box sx={{ minWidth: '250px', border: '0px solid gray', borderColor: 'gray.3' }}>
            <Text></Text>
          </Box>
        </Flex>
      </Container>
    </Layout>
  )

}

export default ArticleTemplate

export const articleQuery = graphql`
  query getArticle($recordId: String!, $appId: String!) {
    article: datoCmsArticle(id: {eq: $recordId}) {
        title
        seo{
          description
          title
        }        
        slug
        summary
        body {
          value
          blocks {
            __typename
            ... on DatoCmsScreenshot {
              id: originalId
              image {
                fluid(
                  imgixParams: { fm: "jpg" }
                  sizes: "(max-width: 700) 100vw, 700px"
                ) {
                  ...GatsbyDatoCmsFluid
                }
                format
                url
              }
            }
            ... on DatoCmsNote {
              id: originalId
              body
            }
            ... on DatoCmsCollapsible {
              id: originalId
              title
              body
              bodyNode {
                childMdx {
                  body
                }
              }
            }
            ... on DatoCmsVideo {
              id: originalId
              source {
                url
                format
              }
              videoUrl
            }
            ... on DatoCmsTable {
              id: originalId
              data
            }
          }
        }
        topic {
          id
          slug
          title
          app {
            id
            slug
            title
          }
          parentTopic {
            id
            slug
            title
            app {
              id
              slug
              title
            }
          }
        }
    }

    related_articles: allDatoCmsArticle(
      filter: {topic: {app: {id: {eq: $appId}}}}
      sort: {fields: displayOrder, order: ASC}
      ) {
        nodes {
          id
          slug
          title
          topic {
            id
            slug
            title
            app {
              id
              slug
              title
            }
          }
        }
    }
  }
`

/** @jsx jsx */
import { jsx } from 'theme-ui'
// import React from 'react'
import { Box, Link as ExternalLink, Flex, Image, Text } from '@theme-ui/components'
import Emoji from "react-emoji-render";
import { Link } from 'gatsby';

export const DocImage = ({ src, alt, caption, float = 'none', width, maxWidth, maxHeight }) => {
  const customWidth = float !== 'none' && float !== 'center' ? '50%': '100%'
  const mb = (float === 'none' || float === 'center') && !caption ? 3 : 0
  const ml = float === 'right' ? 2 : 0
  const mr = float === 'left' ? 3 : 0
  const sx = {
    flexDirection: 'column',
    alignContent: 'center',
    alignItems: 'center',
    float: ['none', float],
    width: ['100%', customWidth],
    ml: [0, ml],
    mr: [0, mr],
    mb: [3, mb],
    maxWidth: ['none', maxWidth],
    maxHeight: ['none', maxHeight],
  }

  const imgWidth = width ? width : '100%'

  return (
    <Flex sx={sx}>
      <Image src={src} alt={alt} sx={{ width: imgWidth }} />
      {caption ? <Text sx={{ mb: 3, p: 2, fontSize: '0.85rem' }}>{caption}</Text> : null}
    </Flex>
  )
}

export const Note = ({ title = "Note", emoji = "📌", children }) => {
  const boxStyle = {
    p: '0.25rem',
    ml: 0,
    my: 3,
    borderWidth: 0,
    flexDirection: 'row',
    border: '1px solid #2d3748',
    borderRadius: '5px'
  };

  return (
    <Flex sx={boxStyle}>
      <Flex sx={{ minWidth: '70px', alignItems: 'center', justifyContent: 'center', fontSize: 4 }}>
        <Emoji sx={{ backgroundColor: 'red' }} text={emoji} />
      </Flex>
      <Box>
        <Flex sx={{ alignItems: 'center' }}>
          <Text sx={{ mb: 2, fontSize: 2, fontWeight: 'bold' }}>{title}</Text>
        </Flex>
        <Box sx={{ fontSize: 1, lineHeight: '1.7rem', color: 'secondary2' }}>{children}</Box>
      </Box>
    </Flex>
  )
}

export const ReadMore = ({ link, label = "ALSO READ: ", title }) => {
  const containerStyle = {
    display: 'block',
    my: 5,
    mx: 2,
    p: 4,
    borderWidth: 1,
    borderColor: 'accent',
    backgroundColor: 'accentExtraLight',
    borderRadius: 8,
    fontSize: 2,
    textAlign: 'center',
  };

  const boxStyle = {
    display: 'inline-block',
    'a': {
      color: 'text',
      fontWeight: 'normal',
      textDecoration: 'underline',
      '&:hover': {
        textDecoration: 'underline',
        color: 'primary',
      },
    },
  };

  const isExternalLink = link.startsWith("http");

  return (
    <Box sx={containerStyle}>
      <Box sx={boxStyle}>
        <Text sx={{ display: 'inline-block' }}>{label}&nbsp;</Text>
        {!isExternalLink && <Link to={link}>{title}</Link>}
        {isExternalLink && <ExternalLink href={link} target='_blank'>{title}</ExternalLink>}
      </Box>
    </Box>
  )
}
